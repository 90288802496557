class UrlConfig {
  getImeiNumbers() {
    return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/GetRMUImei";
  }
  getDevicesURL() {
    return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/GetDevices";
  }

  getLiveDataURL() {
    return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/GetRMUDetails";
  }

  getIMEIdataURL() {
    return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/GetActivatedDevices";
  }
}

export default new UrlConfig();



class UrlConfig {
    addParametersURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/AddDeviceParameter";
    }

    getLiveDataURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/GetRMUDetails";
    }
    registrationURL ()
    {
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/CreateUpdateFramerRegistration"
    }

    getDeviceDetials(){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/GetRegisteredFarmer";
    }

    getInstHistURL(){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/CreateOrUpdateInstallationHistory"
    }

    getInstHistrURL(){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/GetInstallationHistory/"
    }
} 

export default new UrlConfig();

import React, { useState } from "react";
import { Card, Grid, Button, CircularProgress } from "@material-ui/core"; //Checkbox,FormControlLabel,
import { withStyles } from "@material-ui/core/styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { handleLogin } from "../../redux/actions/LoginActions";
// import history from "history.js";

const styles = (theme) => ({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const SignIn = (props) => {
  const [state, setState] = useState({
    username: "",
    password: "",
    // agreement: ""
  });
  let { classes, login } = props;

  const handleChange = (name) => (event) => {
    // event.persist();
    setState({ ...state, [name]: event.target.value });
  };

  const handleFormSubmit = (event) => {
    props.handleLogin({ ...state });
  };

  // useEffect(() => {
  //   if(!login.success){
  //     history.push({
  //       pathname: "/session/signin"
  //     });
  //   }
  // }, [login]);

  return (
    <div className="signup flex justify-center w-full h-full-screen">
      <div className="p-8">
        <Card
          className="signup-card position-relative y-center"
          style={{ width: "700px" }}
        >
          <Grid container>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <div className="p-8 flex justify-center items-center h-full">
                {/* <img src="/assets/images/KBL/kbl_01.jpg" alt="" /> */}
                <img src="/assets/images/KBL/ifcodetech.jpeg" alt="" />
              </div>
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={12}>
              <div className={"p-9 h-full bg-light-gray position-relative"}>
                <ValidatorForm onSubmit={handleFormSubmit}>
                  <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    label="User Name"
                    onChange={handleChange("username")}
                    type="text"
                    name="username"
                    value={state.username}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />
                  <TextValidator
                    className="mb-3 w-full"
                    label="Password"
                    variant="outlined"
                    onChange={handleChange("password")}
                    name="password"
                    type="password"
                    value={state.password}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />
                  {/* <FormControlLabel
                    className="mb-3"
                    name="agreement"
                    onChange={this.handleChange}
                    control={<Checkbox checked />}
                    label="I have read and agree to the terms of service."
                  /> */}
                  <div className="flex flex-wrap items-center mb-4">
                    <div className={classes.wrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={login.loading}
                        type="submit"
                      >
                        Sign in
                      </Button>
                      {login.loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                    {/* <span className="mr-2 ml-5">or</span>
                    <Button
                      className="capitalize"
                      onClick={() =>
                        this.props.history.push("/session/signup")
                      }
                    >
                      Sign up
                    </Button> */}
                  </div>
                  <Button
                    className="text-primary"
                    onClick={() =>
                      props.history.push("/session/forgot-password")
                    }
                  >
                    Forgot password?
                  </Button>
                </ValidatorForm>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  handleLogin: PropTypes.func.isRequired,
  login: state.login,
});

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, { handleLogin })(SignIn))
);

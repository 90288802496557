import AppSession from "./Session";

export const authHeaderWithToken = () => {
  //   let data = AppSession.getItem("auth_user");// localStorage.getItem("auth_user");
  let token = sessionStorage.getItem("jwt_token");
  if (token) {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {};
  }
};

export const authHeaderWithOutToken = () => {
  return { "Content-Type": "application/json" };
};

// let data=JSON.parse(localStorage.getItem("EmployerData"));

import {
  ADD_RMU_PARAMS_LOADING,
  ADD_RMU_PARAMS_SUCCESS,
  ADD_RMU_PARAMS_ERROR,
  DEVICE_REG_LOADING,
  DEVICE_REG_SUCCESS,
  DEVICE_REG_ERROR,
  GET_DEVICE_DETAILS_LOADING,
  GET_DEVICE_DETAILS_SUCCESS,
  GET_DEVICE_DETAILS_ERROR,
  PARAM_MSG_REMOVE,
  CLEAR_RMU_PARAM,
  INST_HIST_SUCCESS,
  INST_HIST_LOADING,
  GET_INST_HIST_LOADING,
  GET_INST_HIST_SUCCESS,
  GET_INST_HIST_ERROR,
  CLEAR_INST_HIST,
} from "../../actions/AddRMUParameters/RMUParametersActions";

const initialState = {
  isSubmitLoading: false,
  updateLoading: false,
  deviceDetails: null,
  isHistLoading: false,
  getInstLoading: false,
  instalHistry: null,
};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case ADD_RMU_PARAMS_LOADING: {
      return {
        ...state,
        isRMULoading: true,
      };
    }

    case ADD_RMU_PARAMS_SUCCESS: {
      return {
        ...state,
        rmuParamData: action.data,
        isRMULoading: false,
        isInserted: true,
        isErrorInserted: false,
      };
    }

    case ADD_RMU_PARAMS_ERROR: {
      return {
        ...state,
        rmuErrorData: action.data,
        isRMULoading: false,
        isErrorInserted: true,
        isInserted: false,
      };
    }

    case PARAM_MSG_REMOVE: {
      return {
        ...state,
        isInserted: false,
      };
    }

    case DEVICE_REG_LOADING: {
      return {
        ...state,
        submitLoading: true,
      };
    }

    case DEVICE_REG_SUCCESS: {
      return {
        ...state,
        registerData: action.data,
        submitLoading: false,
      };
    }

    case DEVICE_REG_ERROR: {
      return {
        ...state,
        rmuErrorData: action.data,
        submitLoading: false,
      };
    }

    case GET_DEVICE_DETAILS_LOADING: {
      return {
        ...state,
        updateLoading: true,
      };
    }

    case GET_DEVICE_DETAILS_SUCCESS: {
      return {
        ...state,
        deviceDetails: action.data,
        updateLoading: false,
      };
    }

    case GET_DEVICE_DETAILS_ERROR: {
      return {
        ...state,
        deviceDetails: null,
        updateLoading: false,
      };
    }

    case CLEAR_RMU_PARAM: {
      return {
        ...state,
        deviceDetails: null,
      };
    }

    case INST_HIST_LOADING: {
      return {
        ...state,
        isHistLoading: true,
      };
    }

    case INST_HIST_SUCCESS: {
      return {
        ...state,
        isHistLoading: false,
      };
    }

    case GET_INST_HIST_LOADING: {
      return {
        ...state,
        getInstLoading: true,
      };
    }

    case GET_INST_HIST_SUCCESS: {
      return {
        ...state,
        instalHistry: action.data,
        getInstLoading: false,
      };
    }

    case GET_INST_HIST_ERROR: {
      return {
        ...state,
        instalHistry: null,
        getInstLoading: false,
      };
    }

    case CLEAR_INST_HIST: {
      return {
        ...state,
        instalHistry: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;

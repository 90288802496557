

import React from "react";
import { authRoles } from "../../auth/authRoles";

const dashboardRoutes = [
  {
    path: "/dashboard/quickaccess",
    component: React.lazy(() => import("./Analytics")),
    //auth: authRoles.admin
  }
];

export default dashboardRoutes;


// import React from "react";
// import { authRoles } from "../../auth/authRoles";

// const dashboardRoutes = [
//   {
//     path: "/dashboard/quickaccess",
//     component: React.lazy(() => import("./Analytics")),
//     //auth: authRoles.admin
//   }
// ];

// export default dashboardRoutes;
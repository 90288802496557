import {
  SET_USER_DATA,
  REMOVE_USER_DATA,
  USER_LOGGED_OUT,
  ADD_USER_LOADING,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  SUB_USER_LOADING,
  SUB_USER_SUCCESS,
  SUB_USER_ERROR,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  SUB_USER_FEATURE_ERROR,
  SUB_USER_FEATURE_SUCCESS,
  SUB_USER_FEATURE_LOADING
} from "../../actions/Admin/UserActions";

const initialState = {
  loading: false,
  subUserLoading: false,
  updateUserLoading: false,
  individualUserLoading: false,
  userDet:{},
  subUser: null
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,userDet: action.data
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...state
      };
    }
    case USER_LOGGED_OUT: {
      return state;
    }
    case ADD_USER_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false
      }
    }

    case CREATE_USER_ERROR: {
      return {
        ...state,
        loading: false
      }
    }

    case SUB_USER_LOADING: {
      return {
        ...state,
        subUserLoading: true
      }
    }

    case SUB_USER_SUCCESS: {
      return {
        ...state,subUser: action.data,
        subUserLoading: false
      }
    }
    case SUB_USER_ERROR: {
      return {
        ...state,
        subUserLoading: false
      }
    }

    case UPDATE_USER_LOADING: {
      return {
        ...state,
        updateUserLoading: true
      }
    }

    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        updateUserLoading: false
      }
    }

    case UPDATE_USER_ERROR: {
      return {
        ...state,
        updateUserLoading: false
      }
    }

    case SUB_USER_FEATURE_LOADING: {
      return {
        ...state,
        individualUserLoading: true
      }
    }

    case SUB_USER_FEATURE_SUCCESS: {
      return {
        ...state,
        individualUserLoading: false
      }
    }

    case SUB_USER_FEATURE_ERROR: {
      return {
        ...state,
        individualUserLoading: false
      }
    }

    default: {
      return state;
    }
  }
};

export default userReducer;

// import history from "history.js";
import axios from "axios";
import { get, post, getWithParams } from "../baseApi";
import { error, success, warning } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken, authHeaderWithOutToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";

export const GET_RMU_IMEI_LOADING = "GET_RMU_IMEI_LOADING";
export const GET_RMU_IMEI_SUCCESS = "GET_RMU_IMEI_SUCCESS";
export const GET_RMU_IMEI_ERROR = "GET_RMU_IMEI_ERROR";

export const RMU_LIVE_DATA_LOADING = "RMU_LIVE_DATA_LOADING";
export const RMU_LIVE_DATA_SUCCESS = "RMU_LIVE_DATA_SUCCESS";
export const RMU_LIVE_DATA_ERROR = "RMU_LIVE_DATA_ERROR";

export const CLEAR_RMU_LIVE_DATA = "CLEAR_RMU_LIVE_DATA";

export const GET_IMEI_DATA_LOADING = "GET_IMEI_DATA_LOADING";
export const GET_IMEI_DATA_SUCCESS = "GET_IMEI_DATA_SUCCESS";
export const GET_IMEI_DATA_ERROR = "GET_IMEI_DATA_ERROR";

export const getInitialData = () => async (dispatch) => {
  let getDevices = UrlConfig.getDevicesURL();
  dispatch({
    type: GET_RMU_IMEI_LOADING,
  });

  let header = authHeaderWithToken();
  let res = {};
  axios
    .all([axios.get(getDevices, { headers: header })])
    .then((resp) => {
      console.log("IMEI: ", resp[0].data);
      res = {
        ...res,
        RmuDevices: resp[0].data,
      };
      dispatch({
        type: GET_RMU_IMEI_SUCCESS,
        data: res,
      });
    })
    .catch((err) => {
      console.error("Error from POST call of ");
      dispatch({
        type: GET_RMU_IMEI_ERROR,
      });
    });
};

export const getRMULiveData = (id) => async (dispatch) => {
  let url = UrlConfig.getLiveDataURL();
  url = `${url}${"/" + id}`;

  dispatch({
    type: RMU_LIVE_DATA_LOADING,
  });

  let header = authHeaderWithToken();
  //let params = {reportid: id };

  let res = await get(url, header);
  if (res && res.status === 200) {
    //res && res.data

    return dispatch({
      type: RMU_LIVE_DATA_SUCCESS,
      data: res.data, //res.data
    });
  } else if (res && res.status === 204) {
    warning("Content not available.");
    return dispatch({
      type: RMU_LIVE_DATA_SUCCESS,
      data: res.data,
    });
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching report...");
    }
    return dispatch({
      type: RMU_LIVE_DATA_ERROR,
      //payload: res.isAxiosError
    });
  }
};

export const clearRMULivedata = () => (dispatch) => {
  return dispatch({
    type: CLEAR_RMU_LIVE_DATA,
  });
};

// export const addOrUpdateCapaRootCause = (payload, isNew) => async (dispatch) => {
//   let url = UrlConfig.addOrUpdateCapaRootCauseURL();
//   // url = `${url}${id}`;

//   dispatch({
//     type: CAPA_ROOTCAUSE_LOADING,
//   });

//   let header = authHeaderWithToken();
//   let res = await post(url, payload, header);
//   if (res && res.status === 200) {
//     //res && res.data
//     let message = `Capa ${isNew ? 'Root Cause submited' : 'updated'} successfully`;
//     success(message);
//     dispatch({
//       type: CAPA_ROOTCAUSE_SUCCESS,
//       data: res.data,
//     });
//     return true;
//   } else if (res && res.isAxiosError) {
//     if (res.response && res.response.data && res.response.data.message) {
//       error(res.response.data.message);
//     } else {
//       error("Error while initiating NCR...");
//     }
//     dispatch({
//       type: CAPA_ROOTCAUSE_ERROR,
//       //payload: res.isAxiosError
//     });
//     return false;
//   }
//   return true;
// };

// export const clearStoredReport = () => dispatch =>{
//   return dispatch({
//     type: NCR_CLEAR_REPORT
//   });
// }

export const getIMEIdata = () => async (dispatch) => {
  let getDevices = UrlConfig.getIMEIdataURL();
  dispatch({
    type: GET_IMEI_DATA_LOADING,
  });

  let header = authHeaderWithToken();
  let res = {};
  axios
    .all([axios.get(getDevices, { headers: header })])
    .then((resp) => {
      console.log("IMEI: ", resp[0].data);
      res = {
        ...res,
        data: resp[0].data,
      };
      dispatch({
        type: GET_IMEI_DATA_SUCCESS,
        data: res,
      });
    })
    .catch((err) => {
      console.error("Error from POST call of ");
      dispatch({
        type: GET_IMEI_DATA_ERROR,
      });
    });
};

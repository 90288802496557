// import AnimationIcons from '../../src/app/views/material-kit/AnimationsIcons';
export const navigations = [
  {
    name: "Dash Board",
    path: "/dashboard/quickaccess",
    icon: "dashboard",
    isAnimate: false,
    type: "",
  },
  {
    name: "RMU Live Data",
    icon: "settings_input_antenna",
    path: "/forms/RMULiveData",
    isAnimate: true,
    type: "loading2",
  },
  {
    name: "RMU Alert View",
    icon: "warning",
    path: "/forms/RMUAlertView",
    isAnimate: false,
    type: "",
  },
  {
    name: "Manage Users",
    path: "/dashboard/quickaccess",
    icon: "dashboard",
    isAnimate: false,
    type: "",
    children: [
      {
        name: "Jalverter Registration",
        path: "/forms/Registration",
        iconText: "D1",
        features: "CreateWorkOrder",
        isAnimate: false,
        type: "",
      },
      {
        name: " Installation History",
        path: "/forms/installationHist",
        iconText: "D2",
        features: "CreateWorkOrder",
        isAnimate: false,
        type: "",
      },
      {
        name: "Submit Installation History",
        path: "/forms/postinstallationHist",
        iconText: "D2",
        features: "CreateWorkOrder",
        isAnimate: false,
        type: "",
      },
    ],
  },
  {
    name: "Device control",
    icon: "add_circle_outlined",
    path: "/forms/AddRMUParameters",
    isAnimate: false,
    type: "",
  },
  {
    name: "Trends & History",
    path: "/dashboard/quickaccess",
    icon: "history",
    isAnimate: false,
    type: "",
    children: [
      {
        name: "Channel History",
        path: "/forms/RMUChannelHistory",
        icon: "view_comfy",
        features: "CreateWorkOrder",
        isAnimate: false,
      },
      {
        name: "Multi Graph",
        path: "/forms/RMUMultiGraph",
        icon: "panorama",
        features: "CreateWorkOrder",
        isAnimate: false,
        type: "",
      },
    ],
  },
];

class UrlConfig {
    login (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/user/Authenticate";
    }

    addUser (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/user/Register";
    }

    getSubUsers(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/user/GetUsers/'
    }

    updateUser(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/user/AddOrUpdateFeature/'
    }

    getSubscribedFeature(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/user/GetUserSubscribedFeature/'
    }
}

export default new UrlConfig();
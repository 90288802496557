class UrlConfig {
  getReportURL() {
    return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/GetRMUReport";
  }

  getGraphDataURL() {
    return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/GetRMUGraphPoints";
  }
  getAlertViewURL() {
    return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/GetDeviceAlertView";
  }
}

export default new UrlConfig();

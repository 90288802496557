import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./Admin/UserReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";
import NavigationReducer from "./NavigationReducer";

//Admin
import DashboardReducer from './Dashboard/DashboardReducer';

//RMU 
import RmuImeiNumbers from './LiveData/RMULiveDataReducer';
import RmuParamReducer from './RMUParameters/RMUParametersReducer';
import reportReducer from './RMUReport/ReportReducer';

const RootReducer = combineReducers({

//Template reducers

layout: LayoutReducer,
scrumboard: ScrumBoardReducer,
notification: NotificationReducer,
ecommerce: EcommerceReducer,
navigations: NavigationReducer,

//App Reducers
login: LoginReducer,
user: UserReducer,
dashboard: DashboardReducer,
RMULiveData:RmuImeiNumbers,
RMUParams:RmuParamReducer,
RMUReport:reportReducer,
});

export default RootReducer;

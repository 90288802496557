import {
  GET_RMU_IMEI_LOADING,
  GET_RMU_IMEI_SUCCESS,
  GET_RMU_IMEI_ERROR,
  RMU_LIVE_DATA_LOADING,
  RMU_LIVE_DATA_SUCCESS,
  RMU_LIVE_DATA_ERROR,
  CLEAR_RMU_LIVE_DATA,
  GET_IMEI_DATA_LOADING,
  GET_IMEI_DATA_SUCCESS,
  GET_IMEI_DATA_ERROR,
} from "../../actions/LiveData/RMULiveDataActions";

const initialState = {
  isSubmitLoading: false,
  getIMEILoading: false,
};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_RMU_IMEI_LOADING: {
      return {
        ...state,
        isIMEILoading: true,
      };
    }

    case GET_RMU_IMEI_SUCCESS: {
      return {
        ...state,
        rmuimeinumbers: action.data,
        isIMEILoading: false,
      };
    }

    case GET_RMU_IMEI_ERROR: {
      return {
        ...state,
        isIMEILoading: false,
      };
    }

    case RMU_LIVE_DATA_LOADING: {
      return {
        ...state,
        isRMULiveDataLoading: true,
      };
    }

    case RMU_LIVE_DATA_SUCCESS: {
      return {
        ...state,
        rmulivedata: action.data,
        isRMULiveDataLoading: false,
      };
    }

    case RMU_LIVE_DATA_ERROR: {
      return {
        ...state,
        isRMULiveDataLoading: false,
      };
    }

    case CLEAR_RMU_LIVE_DATA: {
      return {
        ...state,
        rmulivedata: null,
      };
    }

    case GET_IMEI_DATA_LOADING: {
      return {
        ...state,
        getIMEILoading: true,
      };
    }

    case GET_IMEI_DATA_SUCCESS: {
      return {
        ...state,
        IMEIdata: action.data,
        getIMEILoading: false,
      };
    }

    case GET_IMEI_DATA_ERROR: {
      return {
        ...state,
        getIMEILoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;

import axios from 'axios';
import { error } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";

export const RMU_DASHBOARD_LOADING = "RMU_DASHBOARD_LOADING";
export const RMU_DASHBOARD_SUCCESS = "RMU_DASHBOARD_SUCCESS";
export const RMU_DASHBOARD_ERROR   = "RMU_DASHBOARD_ERROR";

export const DASHBOARD_LOADING = "DASHBOARD_LOADING";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_ERROR =   "DASHBOARD_ERROR";


export const getInitialData = () => async (dispatch) => {
  let getImeiNumbers = UrlConfig.getImeiNumbers();
  dispatch({
    type: RMU_DASHBOARD_LOADING,
  });

  let header = authHeaderWithToken();
  let res = {};
  axios
    .all([
      axios.get(getImeiNumbers, { headers: header }),
    ])
    .then((resp) => {
      console.log("RMU Initial: ", resp[0].data);
      res = {
        ...res,
        RmuImeiNumbers: resp[0].data,
      };
      dispatch({
        type: RMU_DASHBOARD_SUCCESS,
        data: res,
      });
    })
    .catch((err) => {
      console.error("Error from POST call of ");
      dispatch({
        type: RMU_DASHBOARD_ERROR,
      });
    });
};

export const getDashboardData = () => async (dispatch) => {
    let getDeviceStatusURL = UrlConfig.getDeviceStatusURL();
  
    dispatch({
      type: DASHBOARD_LOADING,
    });
  
    let header = authHeaderWithToken();
    let res = {};
  
    axios
      .all([
        axios.get(getDeviceStatusURL, { headers: header }),
      ])
      .then((resp) => {
        console.log("device status: ", resp[0].data);
        res = {
          ...res,
          deviceStatus: resp[0].data,
        };
  
        dispatch({
          type: DASHBOARD_SUCCESS,
          data: res,
        });
      })
      .catch((err) => {
        error("Error while fetching data...");
        dispatch({
          type: DASHBOARD_ERROR,
        });
      });
  };
  
import sessionStorageService from '../../services/sessionStorageService';

class AppSession {
//     setSession = token => {
//     if (token) {
//       sessionStorageService.setItem("jwt_token", token);
//       //axios.defaults.headers.common["Authorization"] = "Bearer " + token;
//     } 
//     // else {
//     //   localStorage.removeItem("jwt_token");
//     //   //delete axios.defaults.headers.common["Authorization"];
//     // }
//   };
  setSession = (token) => {    
    sessionStorageService.setItem("jwt_token", token);
  }

  // Save user to localstorage
  setUser = (user) => {    
    sessionStorageService.setItem("auth_user", user);
  }
  // Remove user from localstorage
  removeUser = () => {
    sessionStorageService.removeItem("auth_user");
  }
  getUser = (user) => {
    sessionStorageService.getItem(user);
  }
}
export default new AppSession();